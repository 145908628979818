export function get(key) {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];

    let j = 0;
    while (j < cookie.length && cookie[j] === ' ') {
      j++;
    }

    let m = 0;
    while (j < cookie.length && m < key.length && cookie[j] === key[m]) {
      m++;
      j++;
    }

    if (m === key.length && cookie[j] === '=') {
      return cookie.substring(j + 1);
    }
  }
  return null;
}

export function set(key, value, exmins) {
  let newCookie = `${key}=${value}`;

  if (exmins) {
    const date = new Date();
    date.setTime(date.getTime() + (exmins * 60 * 1000));
    newCookie += '; expires=' + date.toUTCString() + '; path=/';
  }

  document.cookie = newCookie;
}

export function reset(key) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

export function getUserInfo() {
  const userInfo = get('userInfo');
  if (userInfo) {
    return JSON.parse(userInfo);
  }
  return null;
}

export function setAccessToken(value) {
  if (value) {
    set('accessToken', value, 30);
  } else {
    reset('accessToken')
  }
}

export function getAccessToken() {
  return get('accessToken');
}

export function getParams() {
  return get('params');
}

export function setDeviceId(value) {
  if (value) {
    set('deviceId', value);
  } else {
    reset('deviceId')
  }
}

export function getDeviceId() {
  return get('deviceId');
}

export function getIpAddress() {
  return get('ipAddress');
}
