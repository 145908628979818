import * as React from 'react';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const TermsDetailCollectPersonalData = lazy(() => import('../views/postbox/terms_detail_collect_personal_data'));
const TermsDetailNetworks = lazy(() => import('../views/postbox/terms_detail_networks'));
const TermsDetailProvideThirdParty = lazy(() => import('../views/postbox/terms_detail_provide_third_party'));
const TermsDetailNetworksCollectPersonalData = lazy(() => import('../views/postbox/terms_detail_networks_collect_personal_data'));
const TermsDetailProvideThirdPartyAddress =  lazy(() => import('../views/postbox/terms_detail_provide_third_party_address'));
const TermsDetailReservationCollectPersonalData = lazy(() => import('../views/postbox/terms_detail_reservation_collect_personal_data'));

const PostboxRoute = () => {
  return (
    <Routes>
      <Route path="terms_detail_collect_personal_data" element={<TermsDetailCollectPersonalData />} />
      <Route path="terms_detail_networks" element={<TermsDetailNetworks />} />
      <Route path="terms_detail_provide_third_party" element={<TermsDetailProvideThirdParty />} />
      <Route path="terms_detail_networks_collect_personal_data" element={<TermsDetailNetworksCollectPersonalData />} />
      <Route path="terms_detail_provide_third_party_address" element={<TermsDetailProvideThirdPartyAddress />} />
      <Route path="terms_detail_reservation_collect_personal_data" element={<TermsDetailReservationCollectPersonalData />} />
    </Routes>
  );
};

export default PostboxRoute;
