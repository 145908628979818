import React, {useState} from 'react';
import Spinner from "../views/common/spinner";

const AppContext = React.createContext();

const AppProvider = ({children}) => {
  const [loading, setLoading] = React.useState(false);
  const [isCTAButton, setIsCTAButton] = useState(true);

  const store = {
    loading,
    isCTAButton,
    setLoading,
    setIsCTAButton,
  };

  return (
    <AppContext.Provider value={store}>
      {children}
      {loading && <Spinner/>}
    </AppContext.Provider>
  );
};

export {
  AppProvider,
  AppContext,
};
