import * as React from 'react';
import {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import 'moment/locale/ko';
import App from './app';
import * as Datadog from './utils/datadog_util';
import Spinner from './views/common/spinner';
import NiceModal from "@ebay/nice-modal-react";

import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/style.scss';
import './scss/accordion.scss';
import './scss/tabs.scss';

import 'react-slideshow-image/dist/styles.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {AppProvider} from "contexts/app_context";
import {ThemeProvider} from "styled-components";
import theme from "v2/theme/theme";

async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }
  const {worker} = await import('mocks/browsers.ts');
  // return worker.start();
}


Datadog.init();

enableMocking().then(() => {
  ReactDOM.render(
    <Suspense fallback={<Spinner/>}>
      <BrowserRouter>
        <AppProvider>
          <NiceModal.Provider>
            <ThemeProvider theme={theme} >
              <App/>
            </ThemeProvider>
          </NiceModal.Provider>
        </AppProvider>
      </BrowserRouter>
    </Suspense>,
    document.getElementById('root'),
  );
})

